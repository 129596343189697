import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';
import { MouseEventHandler } from 'react';
import ArrowLeft from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRight from 'remixicon-react/ArrowRightSLineIcon';

import { LeftButton, RightButton } from './styles';
import { CarouselProps } from './types';

export default function useCarouselConfig(): {
  configurableProps: () => CarouselProps;
  isTablet: boolean;
} {
  const isTablet = useMediaQuery(from.tabletLandscape.query);

  const renderArrowPrev = (
    onClickHandler: MouseEventHandler<HTMLButtonElement> | undefined,
    _hasPrev: boolean,
    label: string | undefined,
  ) => (
    <LeftButton
      type="button"
      onClick={onClickHandler}
      title={label}
      disabled={!_hasPrev}
    >
      <ArrowLeft />
    </LeftButton>
  );

  const renderArrowNext = (
    onClickHandler: MouseEventHandler<HTMLButtonElement> | undefined,
    _hasPrev: boolean,
    label: string | undefined,
  ) => (
    <RightButton
      type="button"
      onClick={onClickHandler}
      title={label}
      disabled={!_hasPrev}
    >
      <ArrowRight />
    </RightButton>
  );

  const configurableProps = () => ({
    dynamicHeight: false,
    emulateTouch: true,
    infiniteLoop: false,
    renderArrowNext,
    renderArrowPrev,
    showStatus: false,
    showThumbs: false,
    useKeyboardArrows: true,
  });

  return { configurableProps, isTablet };
}
