import DefaultBathtubIcon from '$/assets/icons/bathtub.svg';
import DefaultBedIcon from '$/assets/icons/bed.svg';
import DefaultImage from '$/components/Atoms/Image';
import { Body1, Body2, Caption, H6 } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import styled, { css } from 'styled-components';

export const Amount = styled(Body2).attrs({ as: 'span' })``;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${from.tabletLandscape`
    margin-top: auto;
  `}
`;

const iconStyles = css`
  height: 1.5rem;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
  width: 1.5rem;
`;

export const BathtubIcon = styled(DefaultBathtubIcon)`
  ${iconStyles}
`;

export const BedIcon = styled(DefaultBedIcon)`
  ${iconStyles}
`;

export const Content = styled.div<{ $hasResponsive: boolean }>`
  background: ${({ theme }) => theme.customTheme.colors.white};
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0.25rem 0.75rem 2rem rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.customTheme.spacing.base};

  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        display: flex;
        flex-direction: column;
        border-radius: 0 1rem 1rem 0;
        padding: ${({ theme }) => theme.customTheme.spacing.lg};
        width: 50%;
      `}
    `}
`;
export const Container = styled.div<{ $hasResponsive: boolean }>`
  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        display: flex;
        height: 16.75rem;
      `}
    `}
`;

export const Item = styled.div`
  align-items: center;
  display: flex;

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.customTheme.spacing.base};
  }
`;

export const Subtitle = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  font-size: 0.875rem;
  line-height: 1.313rem;
`;

export const Title = styled(H6)`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xs']};
`;

export const Top = styled.div`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.md};
`;

export const ImageContainer = styled.div<{ $hasResponsive: boolean }>`
  background-color: ${({ theme }) => theme.customTheme.colors.cream25};
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12.5rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        border-radius: 1rem 0 0 1rem;
        height: 100%;
        width: 50%;
      `}
    `}
`;

export const Price = styled(H6).attrs({ as: 'span' })`
  margin-right: auto;
`;

export const RenewInfo = styled(Caption)`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xs']};
`;

export const Image = styled(DefaultImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
