import Button from '$/components/Atoms/Button';
import ConditionalWrapper from '$/components/Atoms/ConditionalWrapper';
import { formatAvailability } from '$/utils/dates';
import React, { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function UnitCard({
  className,
  onClickContact,
  tag,
  unit,
  url,
  hasResponsive = false,
  hideUpgradePricingEnabled,
}: Props): JSX.Element {
  const isUpgradeInterested = tag === 'INTERESTED';
  const isAvailable = !(tag === 'NOT_AVAILABLE');
  const availableFrom = unit.availabilityDate;
  const availability: string = formatAvailability({ availableFrom });
  return (
    <ConditionalWrapper
      condition={tag !== 'NOT_AVAILABLE' && !isUpgradeInterested}
      wrapper={(children) => (
        <Styled.Link href={url} passHref>
          {children}
        </Styled.Link>
      )}
    >
      <Styled.Container
        $isAvailable={isAvailable}
        $isUpgradeInterested={isUpgradeInterested}
        className={className}
      >
        <Styled.InnerContainer>
          {(tag === 'TOP_RECOMMENDATION' || tag === 'NOT_AVAILABLE') && (
            <Styled.Tag $isAvailable={isAvailable}>
              {tag === 'TOP_RECOMMENDATION' && 'Top recomendation'}
              {tag === 'NOT_AVAILABLE' && 'Not available'}
            </Styled.Tag>
          )}
          {(isAvailable || isUpgradeInterested) && (
            <Styled.Top>
              <Styled.Bonus coins={`$${unit.pointValue}`} />
              {(unit.availabilityDate || isUpgradeInterested) && (
                <Styled.Availability $weight="regular">
                  {availability}
                </Styled.Availability>
              )}
            </Styled.Top>
          )}
          <Styled.ColorFilter $isAvailable={isAvailable || isUpgradeInterested}>
            <Styled.Title $size="lg" $weight="bold">
              Unit {unit.name}
            </Styled.Title>
            <Styled.InfoBlock $hasResponsive={hasResponsive}>
              <Styled.Flex $hasResponsive={hasResponsive}>
                <Styled.Item>
                  <Styled.BedIcon aria-label="Bedrooms amount" />
                  <Styled.Amount>{unit.beds}</Styled.Amount>
                </Styled.Item>
                <Styled.Item>
                  <Styled.BathtubIcon aria-label="Bathrooms amount" />
                  <Styled.Amount>{unit.baths}</Styled.Amount>
                </Styled.Item>
                <Styled.Item>
                  <Styled.DimensionIcon aria-label="Surface area" />
                  <Styled.Amount>{unit.maxSqft} sq ft</Styled.Amount>
                </Styled.Item>
              </Styled.Flex>
              <Styled.Bottom $isAvailable={isAvailable}>
                {(hideUpgradePricingEnabled && (
                  <Styled.Rent $size="lg" $weight="medium">
                    Submit interest for pricing
                  </Styled.Rent>
                )) || (
                  <Styled.Rent
                    $size="lg"
                    $weight="medium"
                  >{`$${unit.rent.toLocaleString('en-US')}`}</Styled.Rent>
                )}
              </Styled.Bottom>
            </Styled.InfoBlock>
          </Styled.ColorFilter>
          {!isAvailable && !isUpgradeInterested && (
            <Button
              label="Contact for availability"
              onClick={onClickContact}
              variant="secondary"
            />
          )}
        </Styled.InnerContainer>
        {isAvailable && !isUpgradeInterested && <Styled.RightArrowIcon />}
      </Styled.Container>
    </ConditionalWrapper>
  );
}

export default memo(UnitCard);
