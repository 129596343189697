/* eslint-disable @typescript-eslint/no-floating-promises */
import { GetUserFutureLeaseQuery } from '$/graphql/generated';
import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import GET_USER_FUTURE_LEASE from '$/graphql/queries/user/getUserFutureLease';
import initVar from '$/graphql/variables/init';
import normalizeFutureLease from '$/model/FutureLease';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

export default function useFutureLease() {
  const status = useLoggedInStatus();
  const [getUserFutureLease, { data: raw, loading, refetch }] =
    useLazyQuery<GetUserFutureLeaseQuery>(GET_USER_FUTURE_LEASE);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser?.futureLease);
    return data && normalizeFutureLease(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserFutureLease();
    }
  }, [getUserFutureLease, status, init]);

  return { data: normalized, loading, refetch };
}
