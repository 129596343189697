import * as Styled from './styles';
import type { Props } from './types';

export default function CtaSection({
  title,
  mainInfo,
  buttonLabel,
  onClick,
  className,
}: Props): JSX.Element {
  return (
    <Styled.Moving className={className}>
      <Styled.MovingSection>
        <Styled.SectionTitle $size="lg" $weight="bold">
          {title}
        </Styled.SectionTitle>
        <Styled.MainInfo>{mainInfo}</Styled.MainInfo>
        <Styled.Button label={buttonLabel} onClick={onClick} />
      </Styled.MovingSection>
    </Styled.Moving>
  );
}
