import DefaultButton from '$/components/Atoms/Button';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import styled from 'styled-components';

export const UpgradeSection = styled.section`
  width: calc(100vw - 2.5rem);
  ${UtilizeSpacer({
    specificOption: {
      size: 'lg',
      direction: 'top',
    },
    type: 'margin',
  })}
  ${from.tabletLandscape`
    width: inherit;
  `};
`;
export const UpgradeHeader = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'bottom',
    },
    type: 'margin',
  })}
  ${from.tabletLandscape`
    display: flex;
    align-items:center;
    justify-content: space-between;
  `};
`;

export const UpgradeInfo = styled.div`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.lg};

  ${from.tabletLandscape`
    margin-bottom: 0;
  `};
`;

export const SeeMoreWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SeeMoreButton = styled(DefaultButton)`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.sm};
  ${from.tabletLandscape`
    margin-bottom:0;
    margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
    width: auto;
  `}
`;
