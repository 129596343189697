import DefaultButton from '$/components/Atoms/Button';
import DefaultImage from '$/components/Atoms/Image';
import MaxWidth from '$/components/Atoms/MaxWidth';
import { Body1, Body2, H5 } from '$/components/Atoms/Typography';
import CtaSection from '$/components/Molecules/CTASection';
import DefaultInlineMessage from '$/components/Molecules/InlineMessage';
import DefaultCashCard from '$/components/Organisms/CashCard';
import DefaultFormWidget from '$/components/Organisms/FormWidget';
import DefaultLeaseCard from '$/components/Organisms/LeaseCard';
import DefaultNewLeaseCard from '$/components/Organisms/NewLeaseCard';
import MainLayout from '$/containers/Layout/Main';
import { from, till } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import RouterLink from 'next/link';
import styled, { css } from 'styled-components';

export const Container = styled(MainLayout)``;

export const FormWidget = styled(DefaultFormWidget)`
  margin-top: -3rem;
`;

export const Text = styled(Body2)`
  display: inline;
  margin-top: ${({ theme }) => theme.customTheme.spacing.sm};
`;

export const SeeMoreWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(H5).attrs({ as: 'h1' })`
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'bottom',
    },
    type: 'margin',
  })}

  ${till.tabletLandscape`
    ${UtilizeSpacer({
      specificOption: {
        size: 'xs',
        direction: 'top',
      },
      type: 'margin',
    })}
  `};
`;

export const CashCard = styled(DefaultCashCard)``;

export const Cards = styled.div<{ $reversed?: boolean }>`
  display: grid;
  grid-gap: 2rem;

  ${from.tabletLandscape`
    grid-template-columns: 2fr 1fr;
  `};

  ${({ $reversed }) =>
    $reversed &&
    css`
      flex-direction: column-reverse;

      ${CashCard} {
        grid-row: 1;
      }

      ${from.tabletLandscape`
        grid-template-columns: 1fr 2fr;
      `};
    `}
`;

export const Content = styled(MaxWidth)<{ $noMovingSection?: boolean }>`
  ${UtilizeSpacer({
    specificOption: {
      size: 'lg',
      direction: 'bottom',
    },
    type: 'padding',
  })}

  ${({ $noMovingSection }) =>
    $noMovingSection &&
    css`
      ${UtilizeSpacer({
        specificOption: {
          size: '2xl',
          direction: 'bottom',
        },
        type: 'padding',
      })}
    `}
`;

export const Header = styled(MaxWidth)<{ $reversed: boolean }>`
  /* TODO: make it so we don't have to have this much code repeated */
  ${UtilizeSpacer({
    options: [
      {
        size: 'md',
        direction: [0],
      },
      {
        size: 'xs',
        direction: [1],
      },
      {
        size: 'sm',
        direction: [2],
      },
    ],
    type: 'padding',
  })}

  ${till.tabletLandscape`
${UtilizeSpacer({
  options: [
    {
      size: 'md',
      direction: [0],
    },
    {
      size: 'sm',
      direction: [1],
    },
    {
      size: 'sm',
      direction: [2],
    },
  ],
  type: 'padding',
})}
`}
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${from.tabletLandscape`
    align-items: center;
    flex-direction: row;
  `};
`;

export const HeaderContentNLI = styled(MaxWidth)`
  ${from.tabletLandscape`
    display: flex;
    align-items: center;
  `};
`;

export const Button = styled(DefaultButton)`
  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'top',
    },
    type: 'margin',
  })}
  ${from.tabletLandscape`
    width: auto;
  `};
`;

export const Logo = styled(DefaultImage)`
  max-width: 100%;
  max-height: 100%;
  padding: ${({ theme }) => theme.customTheme.spacing['2xs']};
`;

export const LogoWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.customTheme.colors.white};
  border-radius: 50%;
  display: flex;
  height: 5rem;
  justify-content: center;
  ${UtilizeSpacer({
    specificOption: {
      size: 'xs',
      direction: 'right',
    },
    type: 'margin',
  })}
  width: 5rem;
`;

export const SectionTitle = styled(H5).attrs({ as: 'h2' })`
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'bottom',
    },
    type: 'margin',
  })}
`;

export const SectionTitleGrey = styled(SectionTitle)`
  color: ${({ theme }) => theme.customTheme.colors.black};

  ${from.tabletLandscape`
    font-size: 1.5rem;
  `};
`;

export const MainInfo = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey600};
  font-weight: 400;
  ${from.tabletLandscape`
    max-width: 50rem;
  `};
`;

export const SearchInfo = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.black};
  margin-top: ${({ theme }) => theme.customTheme.spacing.lg};
`;

export const LeaseCard = styled(DefaultLeaseCard)``;

export const NewLeaseCard = styled(DefaultNewLeaseCard)``;

export const Section = styled.section<{ $reducedSpacing?: boolean }>`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xl']};

  ${({ $reducedSpacing }) =>
    $reducedSpacing &&
    css`
      margin-bottom: ${({ theme }) => theme.customTheme.spacing.lg};
    `}
`;

export const SearchSection = styled.section`
  margin-top: ${({ theme }) => theme.customTheme.spacing.lg};
`;

export const UpgradeHeader = styled.div`
  ${from.tabletLandscape`
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xl']};
  `};
`;

export const UpgradeInfo = styled.div`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.lg};

  ${MainInfo} {
    margin-bottom: 0;
  }

  ${from.tabletLandscape`
    margin-bottom: 0;
  `};
`;

export const DeclineSection = styled.section`
  margin-top: ${({ theme }) => theme.customTheme.spacing['2dot5xl']};
  margin-bottom: ${({ theme }) => theme.customTheme.spacing['2dot5xl']};

  ${Button} {
    width: auto;
  }
`;

export const InlineMessage = styled(DefaultInlineMessage)`
  ${UtilizeSpacer({
    options: [
      {
        size: '0',
        direction: [0],
      },
      {
        size: '0',
        direction: [1],
      },
      {
        size: '2xs',
        direction: [2],
      },
    ],
    type: 'margin',
  })}
`;

export const Pending = styled.div`
  ${from.tabletLandscape`
   margin-top: ${({ theme }) => theme.customTheme.spacing.lg};
  `};
`;

export const PendingGrid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  margin-top: ${({ theme }) => theme.customTheme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xl']};

  ${from.tabletLandscape`
    grid-template-columns: 1fr 1fr 1fr;

  `};
`;

export const CardLink = styled(RouterLink)`
  text-decoration: none;
  color: inherit;
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.customTheme.weights.semiBold};
`;

export const TextLink = styled.a`
  color: ${({ theme }) => theme.customTheme.colors.night400};
  display: block;
`;

export const Moving = styled(CtaSection)`
  ${from.tabletLandscape`
      width: 100%;
      margin-top: auto;
  `};
`;

export const CalculatorCardWrapper = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ theme }) => theme.customTheme.colors.arctic300};
  padding: ${({ theme }) => theme.customTheme.spacing.base};
  height: 19.125rem;
  gap: 8px;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const CalculatorButton = styled(Button)`
  margin-top: ${({ theme }) => theme.customTheme.spacing.base};
`;
