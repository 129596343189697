import DefaultBathtubIcon from '$/assets/icons/bathtub.svg';
import DefaultBedIcon from '$/assets/icons/bed.svg';
import DefaultDimensionIcon from '$/assets/icons/dimension.svg';
import DefaultBonus from '$/components/Atoms/Bonus';
import { Body1, Body2, Caption, Text } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import RouterLink from 'next/link';
import DefaultArrowRight from 'remixicon-react/ArrowRightSLineIcon';
import styled, { css } from 'styled-components';

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  text-decoration: none;
`;

export const InnerContainer = styled.div`
  width: 100%;
`;

export const InfoBlock = styled.div<{ $hasResponsive: boolean }>`
  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletPortrait`
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      `}
    `}
`;

export const ColorFilter = styled.div<{
  $isAvailable?: boolean;
}>`
  ${({ $isAvailable }) =>
    !$isAvailable &&
    css`
      opacity: 0.25;
    `}
`;

export const Container = styled.div<{
  $isAvailable?: boolean;
  $isUpgradeInterested?: boolean;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.customTheme.colors.grey100};
  border-radius: 1rem;
  cursor: default;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  transition: background-color 100ms ease-in;
  ${UtilizeSpacer({
    options: [
      {
        size: '2xs',
        direction: [0],
      },
    ],
    type: 'padding',
  })}

  ${({ $isAvailable, $isUpgradeInterested }) =>
    $isAvailable &&
    !$isUpgradeInterested &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.customTheme.colors.grey200};
        cursor: pointer;
      }
    `}

  ${({ $isUpgradeInterested }) =>
    $isUpgradeInterested &&
    css`
      width: 100%;
    `}
`;

export const Tag = styled(Caption)<{
  $isAvailable: boolean;
}>`
  background-color: ${({ theme }) => theme.customTheme.colors.white};
  border-radius: 0.5rem;
  margin: 0 0 ${({ theme }) => theme.customTheme.spacing.xs};
  padding: ${({ theme }) =>
    `${theme.customTheme.spacing['2xs']} ${theme.customTheme.spacing.xs}`};
  ${({ $isAvailable }) =>
    !$isAvailable &&
    css`
      color: ${({ theme }) => theme.customTheme.colors.error400};
    `};
`;

export const Top = styled.div`
  align-items: center;
  display: flex;
  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'bottom',
    },
    type: 'margin',
  })}
`;

export const Bonus = styled(DefaultBonus)`
  font-size: 10px;
  svg {
    vertical-align: top;
    max-width: 24px;
    height: auto;
  }
`;

export const Availability = styled(Body2).attrs({ as: 'span' })`
  color: ${({ theme }) => theme.customTheme.colors.black};
  margin: 0 0 0 ${({ theme }) => theme.customTheme.spacing.xs};
`;

export const Amount = styled(Body2)`
  font-weight: 400;
`;

export const Flex = styled.div<{ $hasResponsive: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'bottom',
    },
    type: 'margin',
  })}

  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletPortrait`
      `}
    `}
`;

export const Item = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  :not(:last-child) {
    margin-right: ${({ theme }) => theme.customTheme.spacing.base};
  }
`;

export const Title = styled(Text)`
  display: block;
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'bottom',
    },
    type: 'margin',
  })}
`;

export const Bottom = styled.div<{
  $isAvailable?: boolean;
}>`
  align-items: center;
  display: flex;
  ${({ $isAvailable }) =>
    !$isAvailable &&
    css`
      margin-bottom: ${({ theme }) => theme.customTheme.spacing.xs};
    `}
`;

export const Deposit = styled(Body1)``;

export const Rent = styled(Text)`
  margin: 0 ${({ theme }) => theme.customTheme.spacing.xs} 0 0;
`;

const iconStyles = css`
  flex-shrink: 0;
  height: 1.5rem;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
  vertical-align: top;
  width: 1.5rem;
`;

export const BathtubIcon = styled(DefaultBathtubIcon)`
  ${iconStyles}
`;

export const BedIcon = styled(DefaultBedIcon)`
  ${iconStyles}
`;

export const DimensionIcon = styled(DefaultDimensionIcon)`
  ${iconStyles}
`;

export const RightArrowIcon = styled(DefaultArrowRight)`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  height: 1rem;
  width: 1rem;

  ${from.tabletPortrait`
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 1.125rem;
  `}
`;
