import DefaultButton from '$/components/Atoms/Button';
import MaxWidth from '$/components/Atoms/MaxWidth';
import { Body1 } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import styled from 'styled-components';

export const Button = styled(DefaultButton)`
  ${from.tabletLandscape`
    width: auto;
  `};
`;

export const Moving = styled.section`
  ${UtilizeSpacer({
    specificOption: {
      size: 'lg',
      direction: 'top',
    },
    type: 'margin',
  })}

  ${UtilizeSpacer({
    specificOption: {
      size: 'lg',
      direction: 'bottom',
    },
    type: 'padding',
  })}

  ${from.tabletLandscape`
    background-color: ${({ theme }) => theme.customTheme.colors.arctic100};

    ${UtilizeSpacer({
      specificOption: {
        size: 'lg',
        direction: 'vertical',
      },
      type: 'padding',
    })}
  `};
`;

export const SectionTitle = styled(Body1).attrs({ as: 'h2' })`
  color: ${({ theme }) => theme.customTheme.colors.black};

  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'bottom',
    },
    type: 'margin',
  })}
`;

export const MainInfo = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.black};
  font-weight: 400;

  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'bottom',
    },
    type: 'margin',
  })}

  ${from.tabletLandscape`
    max-width: 50rem;
  `};
`;

export const MovingSection = styled(MaxWidth)`
  ${from.tabletLandscape`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Button}{
      margin: 0 auto;
      width: auto;
    }

    ${SectionTitle}{
      font-size: 1.75rem;
      line-height: 140%;
    }

    ${MainInfo}{
      font-size: 1.5rem;
      line-height: 140%;
      max-width: 37rem;
    }
  `};
`;
