import PlaceholderIcon from '$/assets/icons/community.svg';
import { formatMonthDayYear } from '$/utils/dates';
import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

// Not decunstructing an object here yet because we are getting information from units and communities in different use cases
function NewLeaseCard({
  address,
  bathroomsMax,
  bathroomsMin,
  bedroomsMax,
  bedroomsMin,
  city,
  className,
  dateToRenew,
  imageUrl,
  postalCode,
  priceMax,
  priceMin,
  secondary,
  state,
  title,
  hasResponsive = false,
}: Props): JSX.Element {
  const formattedDate = dateToRenew && formatMonthDayYear(dateToRenew);

  return (
    <Styled.Container className={className} $hasResponsive={hasResponsive}>
      <Styled.ImageContainer $hasResponsive={hasResponsive}>
        {imageUrl ? <Styled.Image publicId={imageUrl} /> : <PlaceholderIcon />}
      </Styled.ImageContainer>
      <Styled.Content $hasResponsive={hasResponsive}>
        <Styled.Top>
          <Styled.RenewInfo>
            {formattedDate && `From ${formattedDate}`}
          </Styled.RenewInfo>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Subtitle>
            {address}
            {secondary && `, ${secondary}`}
          </Styled.Subtitle>
          <Styled.Subtitle>
            {city}, {state} {postalCode}
          </Styled.Subtitle>
        </Styled.Top>
        <Styled.Flex>
          <Styled.Price>
            ${priceMin.toLocaleString('en-US')}
            {priceMax &&
              priceMax > priceMin &&
              `-$${priceMax.toLocaleString('en-US')}`}
          </Styled.Price>
          <Styled.Item>
            <Styled.BedIcon aria-label="Bedrooms amount" />
            <Styled.Amount>
              {bedroomsMin}
              {bedroomsMax && bedroomsMax > bedroomsMin && `-${bedroomsMax}`}
            </Styled.Amount>
          </Styled.Item>
          <Styled.Item>
            <Styled.BathtubIcon aria-label="Bathrooms amount" />
            <Styled.Amount>
              {bathroomsMin}
              {bathroomsMax &&
                bathroomsMax > bathroomsMin &&
                `-${bathroomsMax}`}
            </Styled.Amount>
          </Styled.Item>
        </Styled.Flex>
      </Styled.Content>
    </Styled.Container>
  );
}

export default memo(NewLeaseCard);
