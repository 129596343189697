import RecommendedUnits from '$/components/Organisms/RecommendedUnits';
import useUpgradePlaces from '$/graphql/hooks/upgradePlaces/useUpgradePlaces';
import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';
import ArrowRight from 'remixicon-react/ArrowRightSLineIcon';

import * as Styled from './styles';
import type { Props } from './types';

export default function UpgradeSection({
  children,
  greaterLength,
  handleSeeAll,
  tag,
}: Props): JSX.Element | null {
  const { data: upgrade } = useUpgradePlaces();
  const isDesktop = useMediaQuery(from.tabletLandscape.query);
  const upgradeableUnits = upgrade?.units;
  if (upgradeableUnits && upgradeableUnits.length > greaterLength) {
    return (
      <Styled.UpgradeSection>
        <Styled.UpgradeHeader>
          <Styled.UpgradeInfo>{children}</Styled.UpgradeInfo>
          {isDesktop && upgradeableUnits.length > 3 && (
            <Styled.SeeMoreWrapper>
              <Styled.SeeMoreButton
                variant="floating"
                label="See more"
                onClick={handleSeeAll}
              />
              <ArrowRight />
            </Styled.SeeMoreWrapper>
          )}
        </Styled.UpgradeHeader>
        <RecommendedUnits
          units={upgradeableUnits}
          handleSeeAll={handleSeeAll}
          tag={tag}
        />
      </Styled.UpgradeSection>
    );
  }
  return null;
}
