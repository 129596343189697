import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';
import { Text } from '../../Atoms/Typography';

function CashCard({
  amount,
  className,
  onClick,
  secondaryText,
  text,
  title = 'Your rewards',
  variant = 'default',
}: Props): JSX.Element {
  const incentiveOffered = amount > 0;

  return (
    <Styled.CashCardWrapper className={className}>
      <Styled.Container
        $withoutCoinBg={variant === 'withBonusIcons'}
        $differentText={title !== 'Your Renew Cash'}
      >
        <Styled.InnerContainer>
          <Text $size="xl" $weight="bold">
            {title}
          </Text>

          <Styled.TextWrapper>
            <Styled.Amount $weight="bold">${amount}</Styled.Amount>
            <Styled.HelperText>earned</Styled.HelperText>
          </Styled.TextWrapper>

          {text && <Styled.Text>{text}</Styled.Text>}

          {variant === 'withBonusIcons' && (
            <>
              <Styled.Flex>
                <Styled.Bonus coins="$$$" />
                <Styled.Bonus coins="$$" />
                <Styled.Bonus coins="$" />
              </Styled.Flex>
              {secondaryText && <Styled.Text>{secondaryText}</Styled.Text>}
            </>
          )}

          {onClick && (
            <Styled.Button
              label={incentiveOffered ? 'Redeem now' : 'View wallet'}
              onClick={onClick}
            />
          )}
        </Styled.InnerContainer>
      </Styled.Container>
    </Styled.CashCardWrapper>
  );
}

export default memo(CashCard);
