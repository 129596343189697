import { createWidget } from '@typeform/embed';
import { memo, useEffect, useRef } from 'react';

import * as Styled from './styles';
import type { Props } from './types';
import '@typeform/embed/build/css/widget.css';

const FormWidget = ({
  formId,
  className,
  hidden,
  onSubmit,
  hideFooter = false,
  height = 600,
  width,
}: Props): JSX.Element => {
  const typeformElm = useRef<HTMLDivElement>(null);

  useEffect(() => {
    createWidget(formId, {
      container: typeformElm.current as HTMLDivElement,
      hidden,
      onSubmit,
      hideFooter,
      inlineOnMobile: true,
      enableSandbox:
        process.env['NEXT_PUBLIC_TYPEFORM_SANDBOX_MODE']?.toLowerCase() ===
        'true',
      height,
      width,
    });
  }, [formId, height, width, hideFooter, hidden, onSubmit]);

  return <Styled.Container className={className} ref={typeformElm} />;
};

export default memo(FormWidget);
