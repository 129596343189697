import Button from '$/components/Atoms/Button';
import { from } from '$/styles/responsive';
import { Carousel as DefaultSlider } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const Container = styled.div`
  position: relative;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;
`;

export const WhisperContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.customTheme.colors.cream25};
  display: flex;
  justify-content: center;
  & svg {
    color: ${({ theme }) => theme.customTheme.colors.grey500};
    vertical-align: top;
  }
`;

export const DefaultCarousel = styled(DefaultSlider)`
  .carousel {
    overflow: unset;
    margin-bottom: 4.25rem;
    ${from.tabletPortrait`
      border-radius: 0.75rem;
    `};
    .control-dots {
      display: none;
    }
    .slider-wrapper .slider .slide {
      text-align: unset;
      max-width: 100%;
    }
  }
`;

const arrowStyles = css`
  cursor: pointer;
  position: absolute;
  bottom: -4.25rem;
  z-index: 2;
`;

const baseElemStyles = css`
  background: ${({ theme }) => theme.customTheme.colors.cream25};
  border: none;
  border-radius: 50%;
  padding: ${({ theme }) => theme.customTheme.spacing.xs};
`;

export const LeftButton = styled.button`
  ${baseElemStyles}
  ${arrowStyles}
  left: 0;
  & svg {
    vertical-align: top;
    color: ${({ theme }) => theme.customTheme.colors.night400};
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const RightButton = styled.button`
  ${baseElemStyles}
  ${arrowStyles}
  left: 3.25rem;
  & svg {
    vertical-align: top;
    color: ${({ theme }) => theme.customTheme.colors.night400};
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const AllButton = styled(Button)<{ $hide: boolean }>`
  margin: ${({ theme }) => theme.customTheme.spacing.lg} 0 0 auto;
  width: auto;

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}
`;
