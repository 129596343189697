import FUTURE_LEASE_FRAGMENT from '$/graphql/fragments/FutureLease';
import type { GetUserLeaseQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_USER_FUTURE_LEASE: TypedDocumentNode<GetUserLeaseQuery> = gql`
  query GetUserFutureLease {
    currentUser {
      id
      futureLease {
        ...FutureLease
      }
    }
  }

  ${FUTURE_LEASE_FRAGMENT}
`;

export default GET_USER_FUTURE_LEASE;
