import bg from '$/assets/images/backgrounds/card_bg_default.png';
import coins from '$/assets/images/backgrounds/coins_bg.png';
import DefaultBonus from '$/components/Atoms/Bonus';
import DefaultButton from '$/components/Atoms/Button';
import { Body2 as DefaultBody2 } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $withoutCoinBg: boolean;
  $differentText: boolean;
}>`
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  ${UtilizeSpacer({
    options: [
      {
        size: '2xs',
        direction: [0],
      },
    ],
    type: 'padding',
  })}
  position: relative;
  height: 100%;
  background-image: url(${bg});
  background-position: center;

  ${({ $withoutCoinBg }) =>
    !$withoutCoinBg &&
    css`
      ${from.tabletLandscape`
        display: flex;
        align-items: flex-end;
      `}
    `}

  ${({ $withoutCoinBg, $differentText }) =>
    !$withoutCoinBg &&
    !$differentText &&
    css`
      ${from.tabletLandscape`
        background-image:url(${coins}), url(${bg});
        background-size: 40%, cover;
        background-position: 95% 5%, center; 
      `}
    `}
`;

export const CashCardWrapper = styled.div`
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: auto;
  width: 100%;
`;

export const Bonus = styled(DefaultBonus)`
  :not(:last-child) {
    margin-right: ${({ theme }) => theme.customTheme.spacing.base};
  }
`;

export const Text = styled(DefaultBody2)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  display: inline;
`;

export const HelperText = styled(Text).attrs(() => ({ as: 'span' }))`
  font-size: 1rem;
`;

export const Amount = styled(Text).attrs(() => ({ as: 'span' }))`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.customTheme.weights.semiBold};
  line-height: 140%;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
`;

export const TextWrapper = styled.p`
  ${UtilizeSpacer({
    specificOption: {
      size: '3xs',
      direction: 'vertical',
    },
    type: 'margin',
  })}
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${({ theme }) => theme.customTheme.spacing.sm};
`;

export const Button = styled(DefaultButton)`
  ${UtilizeSpacer({
    specificOption: {
      size: 'sm',
      direction: 'top',
    },
    type: 'margin',
  })}
`;
