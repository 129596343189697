import type { FutureLeaseFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeFutureLease(
  input: WithoutNulls<FutureLeaseFragment>,
) {
  return {
    signerStatus: input.signerStatus,
    signingStatus: input.signingStatus,
  };
}

export type FutureLease = ReturnType<typeof normalizeFutureLease>;
