import type { FutureLeaseFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const FUTURE_LEASE_FRAGMENT: TypedDocumentNode<FutureLeaseFragment> = gql`
  fragment FutureLease on FutureLease {
    signerStatus
    signingStatus
  }
`;

export default FUTURE_LEASE_FRAGMENT;
